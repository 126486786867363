import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  CircularProgress
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(9),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontSize: "1.5rem",
  textAlign: "center",
  fontWeight: "bold",
}));

const SignedInSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const SignedInTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "1.25rem",
}));

const AbsentsList = React.memo(() => {
  const navigate = useNavigate();
  const [absentAttendance, setAttendanceAbsent] = useState([]);
  const [isLoading, setLoader] = useState(false); // State to track loading

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const todayDate = getTodayDate();

  useEffect(() => {
    const fetchAllAttendance = async () => {
   
      try {
        setLoader(true); // Start loader
        const response = await fetch(
          `https://hrbp.mapatrawala.com/mapatrawala-hrbp/api/attendance/employees/no-checkin/${todayDate}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setAttendanceAbsent(data);

      } catch (error) {
        console.error("Failed to fetch attendance", error);
      }finally {
        setLoader(false); // Hide loader
      }
    };

    fetchAllAttendance();
  }, [todayDate]);

  const handleClickOpen = useCallback((employee) => {
    navigate(`/attendance/${employee.empId}`);
  }, [navigate]);

  const handleExportAbsents = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Attendance Data");

    // Define the columns
    worksheet.columns = [
     
      { header: "Employee Id", key: "employeeId", width: 15 },
      { header: "Name", key: "name", width: 20 },
      { header: "Date", key: "date", width: 15 },
      { header: "Email Id", key: "emailId", width: 25 },
      { header: "Status", key: "status", width: 10 },
    ];

    // Add data with styling
    absentAttendance.forEach((employee) => {
      const row = worksheet.addRow({
     
        employeeId: employee.employeeId,
        name: `${employee.firstName} ${employee.lastName}`,
        date: todayDate,
        emailId: employee.emailId,
        status: "Absent",
      });

      // Style the status cell
      const statusCell = row.getCell(5);
      statusCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF0000" }, // Red background for "Absent"
      };
      statusCell.font = { bold: true, color: { argb: "FF000000" } };
    });

    // Apply header styling
    worksheet.getRow(1).font = { bold: true, color: { argb: "FFFFFF" } };
    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF0000FF" }, // Blue background
    };

    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "Employee_Absents_Attendance.xlsx");
  }, [absentAttendance, todayDate]);

  return (
    <Container elevation={2}>
      <Title variant="h5">Employee Absent's List</Title>
      <SignedInSection>
        <SignedInTitle variant="h6">
          Employees Absents Attendance(s)
        </SignedInTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Typography variant="h6">Attendance Data</Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#7e31ce",
              color: "#fff",
              "&:hover": { backgroundColor: "#7e31ce" },
            }}
            onClick={handleExportAbsents}
          >
            Export
          </Button>
        </Box>
        <Box mt={5}>
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="tableHeader">
                  <TableRow>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Thumbnail
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Employee Id
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Email Id
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>


                {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100px" // Adjust as needed
                        >
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ):( <TableBody>
                    {absentAttendance.map((employee) => (
                      <TableRow key={employee.id}>
                        <TableCell className="tableCell" style={{ textAlign: 'center' }}>
                          <Avatar
                            alt={employee.firstName}
                            style={{ marginLeft: "35%" }}
                            src={`data:image/jpeg;base64,${employee.profilePic}`}
                          />
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                          }}
                        >
                          {employee.firstName} {employee.lastName}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                          }}
                        >
                          {employee.employeeId}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                          }}
                        >
                          {todayDate}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                          }}
                        >
                          {employee.emailId}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "red",
                            fontSize: "17px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Absent
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "red",
                            fontSize: "17px",
                            textAlign: "center",
                          }}
                        >
                          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#7e31ce",
                                color: "#fff",
                                "&:hover": { backgroundColor: "#7e31ce" },
                                textAlign: "center",
                              }}
                              onClick={() => handleClickOpen(employee)}
                            >
                              Attendance
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>)}
               
              </Table>
            </TableContainer>
          </CardContent>
        </Box>
      </SignedInSection>
    </Container>
  );
});

export default AbsentsList;
