import React, { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HttpsIcon from '@mui/icons-material/Https';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../AuthProvider";

const Topbar = ({ showSearchInput }) => {
  // const theme = useTheme();
  // const colors = tokens("light"); // Ensure tokens are for light mode
  // const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { companyEmailId } = useAuth(); // Get companyEmailId from global state
  console.log("i am company emil " + companyEmailId)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //Log Out---Delete Method
  const handleDelete = async () => {
    try {
      
      const response = await fetch(`https://hrbp.mapatrawala.com/mapatrawala-hrbp/api/verify/delete-org-by-id/${companyEmailId}`, {
        method: 'DELETE', // Specify the HTTP method as DELETE
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer token', // Uncomment and replace with your token if authorization is required
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }


      if (response.ok) {
        
        // Remove companyEmailId from localStorage on successful logout
          localStorage.removeItem('companyEmailId');
      
        // Redirect to the login or home page
          navigate("/");
      }

      // Handle any post-delete actions here, such as updating the UI or redirecting
    } catch (error) {
      console.error('Error deleting resource:', error);

    }
  };



  const handleProfile = () => {
    navigate("/profile");
    handleMenuClose();
  };
  
  const handlePassword = () => {
    navigate("/changePassword");
    handleMenuClose();
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      bgcolor="#E0F5F3"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1100, // Ensure it is above other elements
      }}
    >
      <Box display="flex">
        {broken && !rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}

      </Box>
      <Box display="flex">

        <IconButton onClick={handleMenuOpen}>
          <PersonOutlinedIcon sx={{ fontSize: 35 }}/>
          {/* <PersonIcon sx={{ fontSize: 40 }}/> */}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={handleProfile}
            sx={{
              fontSize: 18,
              fontWeight: 'bold',
              padding: '10px 16px',
              display: 'flex',
              alignItems: 'center',
              // Light background color
              '&:hover': {
                backgroundColor: '#e0e0e0', // Slightly darker hover effect
                color: '#13A9BD', // Change text color on hover
              },
            }}
          >
            <AccountCircleIcon sx={{ marginRight: 1, color: '#13A9BD' }} />
            Profile
          </MenuItem>
          <MenuItem
            onClick={handlePassword}
            sx={{
              fontSize: 18,
              fontWeight: 'bold',
              padding: '10px 16px',
              display: 'flex',
              alignItems: 'center',

              '&:hover': {
                backgroundColor: '#e0e0e0',
                color: '#7e31ce', // Red color for sign out
              },
            }}
          >
            <HttpsIcon sx={{ marginRight: 1, color: '#7e31ce' }} />
            Change Password
          </MenuItem>

          <MenuItem
            onClick={handleDelete}
            sx={{
              fontSize: 18,
              fontWeight: 'bold',
              padding: '10px 16px',
              display: 'flex',
              alignItems: 'center',

              '&:hover': {
                backgroundColor: '#e0e0e0',
                color: '#d32f2f', // Red color for sign out
              },
            }}
          >
            <ExitToAppIcon sx={{ marginRight: 1, color: '#d32f2f' }} />
            Log Out
          </MenuItem>
        </Menu>

        {broken && rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
