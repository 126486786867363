import React, {  useState, useMemo} from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";


const monthNames = [
  'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 
  'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
];


// Utility function to convert numbers to words
const numberToWords = (num) => {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const thousandPowers = ["", "Thousand", "Lakh", "Crore"];

  if (num === 0) return "Zero";

  const getBelowHundred = (n) => {
    if (n < 10) return ones[n];
    if (n < 20) return teens[n - 10];
    return tens[Math.floor(n / 10)] + " " + ones[n % 10];
  };

  const convert = (n) => {
    let str = "";
    let i = 0;
    while (n > 0) {
      let mod = n % 1000;
      if (mod > 0) {
        let belowHundred = getBelowHundred(mod % 100);
        str =
          (mod > 99 ? ones[Math.floor(mod / 100)] + " Hundred " : "") +
          belowHundred +
          " " +
          thousandPowers[i] +
          " " +
          str;
      }
      n = Math.floor(n / 1000);
      i++;
    }
    return str.trim();
  };

  return convert(num) + " Rupees Only";
};


const PaySlipDoc = ({ employeePaySlipId, month, year,data  }) => {
  console.log(month);
  const [bonus] = useState(20000);
  
  
 
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const navigate=useNavigate()
  


  // const monthName = month >= 1 && month <= 12 ? monthNames[month - 1] : null;

  const monthName = month >= 1 && month <= 12 ? monthNames[parseInt(month, 10) ] : 'JANUARY';

   console.log(monthName);

  const generatePdf = async (input, saveToBackend = false) => {
    try {
      
      if (saveToBackend) {
        setIsSaving(true); // Set saving state to true
      } else {
        setIsDownloading(true); // Set downloading state to true
      }
  
      const canvas = await html2canvas(input, {
        backgroundColor: "white",
        scale: 2,
      });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

    

      // const firstName = data.firstName || 'Employee';
      // const lastName = data.employee.lastName || 'Name';
      // const monthYear = parseInt(month) + 1 + "/" + year;
      // const fileName = `${firstName}_${lastName}_PaySlip_${monthYear}.pdf`;

      const firstName = data.firstName || 'Employee';
const lastName = data.employee.lastName || 'Name';
const formattedMonth = (parseInt(month) + 1).toString().padStart(2, "0"); // Ensure 2-digit month
const monthYear = `${formattedMonth}/${year}`; // Format as "MM/YYYY"
const fileName = `${firstName}_${lastName}_PaySlip_${monthYear}.pdf`;

  
      if (saveToBackend) {
        const pdfBlob = pdf.output("blob");
        const formData = new FormData();
        formData.append("paySlipPdf", pdfBlob, "payslip.pdf");
        formData.append("month", "1");
  
        try {
          const response = await fetch(
           ` https://hrbp.mapatrawala.com/mapatrawala-hrbp/api/payslip/save/${employeePaySlipId}`,
            {
              method: "PUT",
              body: formData,
            }
          );
          if (!response.ok) {
            throw new Error("Failed to save PDF");
          }
  
          Swal.fire("Success", "PaySlip saved Successfully!", "success");
          
          // Persist the button state in localStorage
          localStorage.setItem('paySlipSaved', 'true');
          navigate('/paySlip'); // Navigate to another component on success
        } catch (error) {
          Swal.fire("Error", "Error saving PaySlip: " + error.message, "error");
        }
      } else {
        pdf.save(fileName);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      if (saveToBackend) {
        setIsSaving(false); // Reset saving state
      } else {
        setIsDownloading(false); // Reset downloading state
      }
    }
  };
  
  
  
  
  
  
  const downloadPdf = () => {
    const input = document.getElementById("payslip");
    generatePdf(input, false); // Trigger only the download action
  };
  
  // const savePdf = () => {
  //   const input = document.getElementById("payslip");
  //   generatePdf(input, true); // Trigger only the save action
  // };
  
  

  // const handleBonusChange = (event) => setBonus(event.target.value);
  // Convert the Base64 string into a data URL
  const companyStampSrc = `data:image/jpeg;base64,${data.employee.organisation.companyStamp}`;
  const companyLogoSrc = `data:image/jpeg;base64,${data.employee.organisation.companyLogo}`;
  

  // Memoize the rendered content based on data
  const memoizedPaySlipContent = useMemo(
    () => (
      <div id="payslip" style={styles.payslipContainer}>
        <header style={styles.payslipHeader}>
          <div sty >
            <img
              src={companyLogoSrc}
              alt="Company Logo"
              style={styles.companyLogoImage}
            />
          </div>
          <div style={styles.companyDetails}>
            <h2>{data.employee.organisation.companyName}</h2>
            {/* <p>Global Innovative Private Limited</p> */}
            <p>{data.employee.organisation.companyAddress}</p>
            <p>{data.employee.organisation.city} {data.employee.organisation.state}</p>
            <p>{data.employee.organisation.pincode}, India</p>
            <p>{data.employee.organisation.contactNumber}</p>
          </div>
        </header>
       <div>
       <h4 style={styles.payslipTitle}>PAYSLIP FOR THE MONTH OF {monthName} {year}</h4>
       <hr style={{marginBottom:'30px'}}/>
        </div>
        


  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
    {/* First Row */}
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', width: '100%' }}>
      <div style={{ flex: 0.5,}}></div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>Name:</div>
      <div style={{ flex: 1.8, textAlign: 'left' }}>{data.firstName} {data.employee.lastName}</div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>UAN No:</div>
      <div style={{ flex: 2, textAlign: 'left', wordWrap: 'break-word' }}>{data.uanNo}</div>
    </div>

    {/* Second Row */}
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', width: '100%' }}>
      <div style={{ flex: 0.5,}}></div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>Employee Code:</div>
      <div style={{ flex: 1.8, textAlign: 'left' }}>{data.employeeId}</div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>Bank Name:</div>
      <div style={{ flex: 2, textAlign: 'left', wordWrap: 'break-word' }}>{data.bankName}</div>
    </div>

    {/* Third Row */}
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', width: '100%' }}>
      <div style={{ flex: 0.5,}}></div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>Designation:</div>
      <div style={{ flex: 1.8, textAlign: 'left' }}>{data.designation}</div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>Account No:</div>
      <div style={{ flex: 2, textAlign: 'left', wordWrap: 'break-word' }}>{data.accountNo}</div>
    </div>

    {/* Fourth Row */}
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', width: '100%' }}>
      <div style={{ flex: 0.5,}}></div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>Department:</div>
      <div style={{ flex: 1.8, textAlign: 'left' }}>{data.department}</div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>PAN No:</div>
      <div style={{ flex: 2, textAlign: 'left', wordWrap: 'break-word' }}>{data.panNo}</div>
    </div>

    {/* Fifth Row */}
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', width: '100%' }}>
      <div style={{ flex: 0.5,}}></div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>Total Days Absent:</div>
      <div style={{ flex: 1.8, textAlign: 'left' }}>{data.noOfDaysAbsent}</div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>Total Days Present:</div>
      <div style={{ flex: 2, textAlign: 'left', wordWrap: 'break-word' }}>{data.noOfDaysPresent}</div>
    </div>
    
    {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', width: '100%' }}>
      <div style={{ flex: 0.5,}}></div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}>Date of Joining:</div>
      <div style={{ flex: 1.8, textAlign: 'left' }}>{data.dateOfJoining}</div>
      <div style={{ flex: 1.5, fontWeight: 'bold' }}></div>
      <div style={{ flex: 2, textAlign: 'left', wordWrap: 'break-word' }}></div>
    </div> */}


  </div>



        
        <table style={styles.payslipTable}>
          <thead>
            <tr>
              <th style={{ ...styles.payslipThTd, ...styles.payslipTh }}>
                Earnings
              </th>
              <th style={{ ...styles.payslipTd, ...styles.payslipTh }}>
                Amount
              </th>
              <th style={{ ...styles.payslipThTd, ...styles.payslipTh }}>
                Deductions
              </th>
              <th style={{ ...styles.payslipTd, ...styles.payslipTh }}>
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.payslipThTd}>Basic Salary</td>
              <td style={styles.payslipTd}> {data.basicSalary}</td>
              <td style={styles.payslipThTd}>Tax</td>
              <td style={styles.payslipTd}> {data.tax}</td>
            </tr>
            <tr>
              <td style={styles.payslipThTd}>Bonus</td>
              <td style={styles.payslipTd}> {data.bonus}</td>
              <td style={styles.payslipThTd}>PF</td>
              <td style={styles.payslipTd}> {data.pf}</td>
            </tr>
            <tr>
              <td style={styles.payslipThTd}>Other Earnings</td>
              <td style={styles.payslipTd}> {data.otherEarnings}</td>
              <td style={styles.payslipThTd}>Loan Repayment</td>
              <td style={styles.payslipTd}> {data.loanRepayment}</td>
            </tr>
            <tr>
              <td style={styles.payslipThTd}>Work Basis Pay</td>
              <td style={styles.payslipTd}> {data.workBasePay}</td>
              <td style={styles.payslipThTd}>Early Leaving Fine</td>
              <td style={styles.payslipTd}>{data.earlyLeavingFine}</td>
            </tr>
            <tr>
              <td style={styles.payslipThTd}>Over Time</td>
              <td style={styles.payslipTd}> {data.overTime}</td>
              <td style={styles.payslipThTd}>Late Coming Fine</td>
              <td style={styles.payslipTd}>{data.lateComingFine}</td>
            </tr>
            <tr>
              <td style={styles.payslipThTd}>Incentive</td>
              <td style={styles.payslipTd}> {data.incentive}</td>
              <td style={styles.payslipThTd}>Other Deductions</td>
              <td style={styles.payslipTd}>{data.otherDeduction}</td>
            </tr>
            <tr>
              <td style={styles.payslipThTd}>
                <strong>Total Earnings</strong>
              </td>
              <td style={styles.payslipTd}> {data.totalpay}</td>
              <td style={styles.payslipThTd}>
                <strong>Total Deductions</strong>
              </td>
              <td style={styles.payslipTd}> {data.totalDeduction}</td>
            </tr>

              {/* Net Pay Row */}
            {/* <tr>
           
              <td 
              style={{textAlign:'right'}}
              >
               
              </td>
            </tr> */}
          </tbody>
          
        </table>
        <h3   style={{textAlign:'right'}}> Net Pay: {data.netPay}</h3>

        {/* <div style={styles.payslipTotal}>
          <strong>Net Pay:{data.netPay}</strong>
        </div> */}
        
        <div style={styles.rowContainer}>
        {/* Company Stamp Image */}
    

        {/* Net Payable Information */}
        <div>
          <div style={styles.payslipTotalInWords}>
            <strong>
              Net Payable (in words): {numberToWords(data.netPay)}
            </strong>
          </div>
          <div style={styles.payslipTotalInWordss}>
            <strong>
              This is a system generated payslip and does not require a signature
            </strong>
          </div>
        </div>

        <img src={companyStampSrc} alt="Company Stamp" style={styles.companyStampImage} />
      </div>
        
        
      </div>
    ),
    // [data, bonus]
    [data,bonus, companyLogoSrc, companyStampSrc, monthName, year]
  );

  return (
    <div>
      {memoizedPaySlipContent}
      <button
        onClick={downloadPdf} disabled={isDownloading || isSaving}
        style={{
          backgroundColor: "#7e31ce",
          color: "#fff",
          // borderRadius: "0px",
          border: "none",
          borderRadius: "5px",
          marginTop: "30px",
          cursor:"pointer",
          width:'18%',
          height:"35px"
        }}
      
      >
         {isDownloading ? "Payslip Downloading..." : "Download Payslip"}
      </button>
      {/* <button
        onClick={savePdf} disabled={isDownloading || isSaving}
        style={{
          backgroundColor: "#7e31ce",
          color: "#fff",
          borderRadius: "0px",
          border: "none",
          borderRadius: "5px",
          marginTop: "30px",
        }}
         
      >
        {isSaving ? "Payslip Saving..." : "Save Payslip"}
      </button> */}
    </div>
  );
};

const styles = {
  payslipContainer: {
    width: "700px",
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    fontFamily: "Arial, sans-serif",
    fontSize: "12px",
  },
  payslipTotalInWords: {
    textAlign: "left",
    fontSize: "14px",
    // fontStyle: "italic",
    // color: "#555",
    fontWeight: "bold",
    marginTop: "60px",
  },
  payslipTotalInWordss: {
    textAlign: "center",
    fontSize: "10px",
    // fontStyle: "italic",
    color: "#737373",
    // fontWeight:'bold'
    marginTop: "20px",
  },
  payslipHeader: {
    display: "flex",
    marginBottom: "20px",
  },
  companyLogo: {
    marginRight: "20px",
  },
  companyLogoImage: {
    width: "130px",
    height: "120px",
    marginTop:'20px',
    borderRadius:'20px'
  },
  companyDetails: {
    flex: 1,
    textAlign: "right",
  },
  payslipTitle: {
    textAlign: "center",
    // textDecoration: "underline",
    fontSize: "14px",
    // marginBottom: "27px",
  },
  payslipDetails: {
    marginBottom: "20px",
  },
  payslipRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  payslipLabel: {
    width: "40%",
    fontWeight: "bold",
  },
  payslipLabel2: {
    width: "35%",
    fontWeight: "bold",
  },
  payslipValue: {
    width: "30%",
    // textAlign: "right",
  },
  payslipValue2: {
    width: "23%",
    // textAlign: "right",
  },
  payslipTable: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  },
  payslipThTd: {
    border: "0.5px solid #000",
    padding: "8px",
    textAlign: "left",
  },
  payslipTd: {
    border: "0.5px solid #000",
    padding: "8px",
    textAlign: "center",
  },
  payslipTh: {
    backgroundColor: "pink",
    // textAlign: "center",
  },
  payslipTotal: {
    textAlign: "right",
    fontWeight: "bold",
    fontSize: "12px",
  },

  rowContainer: {
    display: 'flex',
    alignItems: 'center', // Align items vertically centered
    justifyContent: 'space-between', // Space between the items
    marginBottom: '20px', // Space below the row
  },
  payslipTotalInWords: {
    marginLeft: '20px', // Space between the image and text
  },
  // payslipTotalInWordss: {
  //   marginTop: '10px',
  // },
  companyStampImage: {
    height: '80px', // Adjust the height of the image as needed
    marginRight: '20px', // Space to the right of the image
    marginTop:'20px',
    width:"80px"
  },
};

export default PaySlipDoc;