import React, { useState, useEffect , useCallback } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  TextField,
  CircularProgress,
  useMediaQuery,
  TablePagination,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import PaySlipDoc from "../PaySlipDocument/DocPaySlip";
import swal from "sweetalert2"; // Ensure swal is imported

function EmployeePaySlip() {
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = useState(false);
  const [employeePaySlipId, setEmployeePaySlipId] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [paySlipData, setPaySlipData] = useState(null); // State for holding pay slip data
  const [openEditModal, setOpenEditModal] = useState(false); // State to manage edit modal
  const [, setSelectedEmployee] = useState(null); // State for selected employee
  const [editableEmployee, setEditableEmployee] = useState(null); // State for the employee being edited
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();

  // useEffect(() => {
  //   fetchEmployees();
  // }, [page, rowsPerPage]);

  const fetchEmployees = useCallback(async () => {
    try {
      
      setLoader(true); // Show loader
      const response = await fetch(
        `https://hrbp.mapatrawala.com/mapatrawala-hrbp/api/employee/getAll`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch");
      }
      const data = await response.json();
      const employeesWithImages = data.map((employee) => ({
        ...employee,
        profilePic: `data:image/jpeg;base64,${employee.profilePic}`,
      }));
      setEmployees(employeesWithImages);
      
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoader(false); // Hide loader
    }
  },[page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps


 // useEffect to fetch employees when page or rowsPerPage changes
 useEffect(() => {
  fetchEmployees();
}, [fetchEmployees]);



  const fetchPaySlipData = async () => {
    try {
      
       // Format the month as "09"
    const formattedMonth = (selectedMonth + 1).toString().padStart(2, "0");
      const response = await fetch(
        `https://hrbp.mapatrawala.com/mapatrawala-hrbp/api/payslip/getPayslip/${employeePaySlipId}/${formattedMonth}/${selectedYear}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch pay slip data");
      }
      const data = await response.json();
      setPaySlipData(data); // Set fetched data to state
    } catch (error) {
      console.error("Error fetching pay slip data:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEmployee(null); // Clear selected employee when modal is closed
    setPaySlipData(null); // Clear pay slip data when modal is closed
  };

  const handleClickOpen = (employee) => {
    setSelectedEmployee(employee);
    setEmployeePaySlipId(employee.employeeId); // Set the pay slip ID
    setOpen(true);
  };

  // const handleGetData = () => {
  //   fetchPaySlipData(); // Fetch data when button is clicked
  // };

  const handleGetData = async () => {
    setLoading(true); // Start loading
    try {
      await fetchPaySlipData(); // Fetch data when the button is clicked
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  };

  const handleEditClick = (employee) => {
    setEditableEmployee(employee);
    setOpenEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditableEmployee((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      
      // Create a FormData object
      const formData = new FormData();

      // Manually append each required field to FormData
      formData.append("bonus", editableEmployee.bonus || 0);
      formData.append("otherEarnings", editableEmployee.otherEarnings || 0);
      formData.append("workBasisPay", editableEmployee.workBasisPay || 0);
      formData.append("overTime", editableEmployee.overTime || 0);
      formData.append("incentive", editableEmployee.incentive || 0);
      formData.append("tax", editableEmployee.tax || 0);
      formData.append("pf", editableEmployee.pf || 0);
      formData.append("loanRepayment", editableEmployee.loanRepayment || 0);
      formData.append(
        "earlyLeavingFine",
        editableEmployee.earlyLeavingFine || 0
      );
      formData.append("lateComingFine", editableEmployee.lateComingFine || 0);
      formData.append("otherDeductions", editableEmployee.otherDeductions || 0);
      formData.append("hra", editableEmployee.hra || 0);
      formData.append("dra", editableEmployee.dra || 0);
      formData.append("petrolAllowance", editableEmployee.petrolAllowance || 0);
      formData.append("foodAllowance", editableEmployee.foodAllowance || 0);
      formData.append("basicSalary", editableEmployee.basicSalary || 0);

      // Send the PUT request to update employee data
      const response = await fetch(
        `https://hrbp.mapatrawala.com/mapatrawala-hrbp/api/employee/salary/update/${editableEmployee.empId}`,
        {
          method: "PUT",
          body: formData, // Send FormData as the request body
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update employee data");
      }

       // Show SweetAlert success message
        swal.fire({
          title: "Success!",
          text: "Salary BreakDown Data Updated Successfully",
          icon: "success",
          confirmButtonText: "OK",
        });


      setOpenEditModal(false);
      fetchEmployees(); // Refresh employee data
    } catch (error) {
      console.error("Error updating employee data:", error);

      // Show SweetAlert error message
      swal.fire({
        title: "Error!",
        text: "Failed to update employee data",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleClickAttendanceOpen = (employee) => {
    navigate(`/attendance/${employee.empId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Box mt={9} marginLeft={2} marginRight={2}>
        <Card
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          }}
        >
          <CardContent>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h5" sx={{ fontSize: 20, marginBottom: 10 }}>
                <strong>Employees Payroll</strong>
              </Typography>
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="tableHeader">
                  <TableRow>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Thumbnail
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Employee Id
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Employee Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Mobile
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      E-mail
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {loader ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100px" // Adjust as needed
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableBody>
                    {employees
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((employee) => (
                        <TableRow key={employee.id}>
                          <TableCell className="tableCell" style={{border: "0.1rem solid black",}}>
                            <Avatar
                              alt={employee.firstName}
                              style={{ marginLeft: 45 }}
                              src={employee.profilePic}
                            />
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "0.1rem solid black",
                            }}
                          >
                            {employee.employeeId}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "0.1rem solid black",
                            }}
                          >
                            {employee.firstName} {employee.lastName}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "0.1rem solid black",
                            }}
                          >
                            {employee.contactNumber1}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "0.1rem solid black",
                            }}
                          >
                            {employee.emailId}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "12px",
                              textAlign: "center",
                              border: "0.1rem solid black",
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection={isMobile ? "column" : "row"}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#7e31ce",
                                  color: "#fff",
                                  "&:hover": { backgroundColor: "#7e31ce" },
                                  marginBottom: isMobile ? 1 : 0, // Stack buttons vertically with space in mobile
                                  marginRight: isMobile ? 0 : 2,
                                  // fontWeight: "bold",
                                  width: isMobile ? "100%" : "auto", // Full width for mobile
                                }}
                                onClick={() =>
                                  handleClickAttendanceOpen(employee)
                                }
                              >
                                Attendance
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#7e31ce",
                                  color: "#fff",
                                  "&:hover": { backgroundColor: "#7e31ce" },
                                  textAlign: "center",
                                  marginBottom: isMobile ? 1 : 0,
                                  marginRight: isMobile ? 0 : 1,
                                  // fontWeight: "bold",
                                  width: isMobile ? "100%" : "auto",
                                }}
                                onClick={() => handleClickOpen(employee)}
                              >
                                View PaySlip
                              </Button>
                              <IconButton
                                sx={{
                                  backgroundColor: "#7e31ce",
                                  color: "#fff",
                                  border: "2px solid #7e31ce",
                                  borderRadius: "4px",
                                  width: isMobile ? "100%" : "55px",
                                  height: isMobile ? "40%" : "33px",
                                  "&:hover": {
                                    backgroundColor: "#6e27b1",
                                    borderColor: "#6e27b1",
                                  },
                                  textAlign: "center",
                                  padding: 0,
                                  marginBottom: isMobile ? 1 : 0,
                                  marginRight: isMobile ? 0 : 2,
                                  marginLeft: isMobile ? 0 : 2,
                                  fontSize:17
                                }}
                                onClick={() => handleEditClick(employee)}
                              >
                                <EditIcon />
                                {isMobile ? null : "sb"}
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={employees.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle style={{fontWeight:'bold',fontSize:'20px'}}>Employee Monthly PaySlip</DialogTitle>
        <DialogContent>
          <Box>
            <FormControl fullWidth margin="normal">
              <InputLabel>Month</InputLabel>
              <Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                label="Month"
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <MenuItem key={i} value={i}>
                    {new Date(0, i).toLocaleString("default", {
                      month: "long",
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Year</InputLabel>
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                label="Year"
              >
                {[2024, 2025].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <Button
              variant="contained"
              onClick={handleGetData}
              sx={{
                backgroundColor: "#7e31ce",
                color: "#fff",
                "&:hover": { backgroundColor: "#7e31ce" },
                marginTop: "16px",
              }}
            >
              Get Data
            </Button> */}

<Button
      variant="contained"
      onClick={handleGetData}
      sx={{
        backgroundColor: "#7e31ce",
        color: "#fff",
        "&:hover": { backgroundColor: "#7e31ce" },
        marginTop: "16px",
        minWidth: "120px", // Ensures the button doesn't shrink
        height: "40px", // Sets a height for alignment
      }}
      disabled={loading} // Disables the button when loading
    >
      {loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        "Get Data"
      )}
    </Button>




            {/* <h4 style={{ textAlign: "center" }}>
              PAYSLIP FOR THE MONTH OF{" "}
              {new Date(selectedYear, selectedMonth)
                .toLocaleString("default", { month: "long" })
                .toUpperCase()}{" "}
              {selectedYear}
            </h4> */}
          </Box>
          {paySlipData && (
            <PaySlipDoc
              data={paySlipData} // Pass the fetched data
              employeePaySlipId={employeePaySlipId}
              month={selectedMonth.toString().padStart(2, "0")} // Format month as "09"
              year={selectedYear}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: "#7e31ce",
              color: "#fff",
              // borderRadius: "0px",
              border: "none",
              borderRadius: "5px",
              marginTop: "30px",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        maxWidth="sm"
      >
        <DialogTitle style={{fontWeight:'bold',fontSize:'20px'}}>Edit Employee Salary Breakdown Form</DialogTitle>
        <DialogContent>
          {editableEmployee && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Basic Salary"
                    name="basicSalary"
                    value={editableEmployee.basicSalary}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Bonus"
                    name="bonus"
                    value={editableEmployee.bonus}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Other Earnings"
                    name="otherEarnings"
                    value={editableEmployee.otherEarnings}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Work Basis Pay"
                    name="workBasisPay"
                    value={editableEmployee.workBasisPay}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Incentive"
                    name="incentive"
                    value={editableEmployee.incentive}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Over Time"
                    name="overTime"
                    value={editableEmployee.overTime}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Tax"
                    name="tax"
                    value={editableEmployee.tax}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="PF"
                    name="pf"
                    value={editableEmployee.pf}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="HRA"
                    name="hra"
                    value={editableEmployee.hra}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="DRA"
                    name="dra"
                    value={editableEmployee.dra}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Food Allowance"
                    name="foodAllowance"
                    value={editableEmployee.foodAllowance}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Petrol Allowance"
                    name="petrolAllowance"
                    value={editableEmployee.petrolAllowance}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Other Deductions"
                    name="otherDeductions"
                    value={editableEmployee.otherDeductions}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Late Coming Fine"
                    name="lateComingFine"
                    value={editableEmployee.lateComingFine}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Early Leaving Fine"
                    name="earlyLeavingFine"
                    value={editableEmployee.earlyLeavingFine}
                    onChange={handleEditChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Loan Repayment"
                    name="loanRepayment"
                    value={editableEmployee.loanRepayment}
                    onChange={handleEditChange}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: "#7e31ce",
              color: "#fff",
              borderRadius: "5px",
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => setOpenEditModal(false)}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: "#7e31ce",
              color: "#fff",
              borderRadius: "5px",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default EmployeePaySlip;
