import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import { styled, useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(9),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontSize: "1.5rem",
  textAlign: "center",
  fontWeight: "bold",
}));

const SignedInSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const SignedInTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "1.25rem",
}));

const EmployeeAttendance = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [attendance, setAttendance] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleOpenModal = (employee) => {
    setSelectedEmployee(employee);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedEmployee(null);
  };

  const TableHeaderCell = styled(TableCell)(({ theme }) => ({
    color: "white",
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "bold",
    border: "0.1rem solid black",
  }));

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const todayDate = getTodayDate();

  //new addded code
  useEffect(() => {
    const fetchAllAttendance = async () => {
      try {
        setLoader(true); // Show loader
        const response = await fetch(
          `https://hrbp.mapatrawala.com/mapatrawala-hrbp/api/attendance/employees/all/${todayDate}`
        );
        const data = await response.json();

        const todayAttendace = data.filter(
          (employee) => employee.date === todayDate
        );
        const employeesWithImages = todayAttendace.map((employee) => ({
          ...employee,
          checkInPic: `data:image/jpeg;base64,${employee.checkInPic}`,
          checkOutPic: `data:image/jpeg;base64,${employee.checkOutPic}`
        }));

        // Sort employeesWithImages so newly added rows appear on top
        const sortedAttendance = employeesWithImages.reverse();

        setAttendance(sortedAttendance);
      } catch (error) {
        console.error("Failed to fetch attendance", error);
      } finally {
        setLoader(false); // Hide loader
      }
    };

    fetchAllAttendance();
  }, [todayDate]);

  //for punch in location
  const truncateString = (str, num) => {
    if (typeof str !== "string") {
      return "Punch In Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  //for punch out location
  const truncateString1 = (str, num) => {
    if (typeof str !== "string") {
      return "Punch Out Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  // const formatTimeFromArray = (timeArray) => {
  //   if (Array.isArray(timeArray) && timeArray.length >= 2) {
  //     const hours = String(timeArray[0]).padStart(2, "0");
  //     const minutes = String(timeArray[1]).padStart(2, "0");
  //     return ` ${hours}:${minutes}`;
  //   }
  //   return "N/A";
  // };
  const formatTimeFromArray = (timeArray) => {
    if (Array.isArray(timeArray) && timeArray.length === 2) {
      const [hours, minutes] = timeArray;
      if (hours != null && minutes != null) {
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}`;
      }
    }
    // Return "N/A" if the timeArray is invalid or doesn't contain two elements
    return "N/A";
  };
  


  const handleClickOpen = (employee) => {
    navigate(`/attendance/${employee.empId}`);
  };

  // const handleExport = async () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Attendance Data");

  //   worksheet.columns = [
  //     { header: "Employee ID", key: "employeeId", width: 15 },
  //     { header: "Employee Name", key: "firstName", width: 15 },

  //     { header: "Date", key: "date", width: 15 },
  //     { header: "Punch In Time", key: "checkIn", width: 20 },
  //     { header: "Punch Out Time", key: "checkOut", width: 20 },
  //     { header: "Location", key: "checkinLocation", width: 30 },
  //     { header: "Status", key: "status", width: 15 }, // The status column is the 6th column
  //   ];

  //   attendance.forEach((employee) => {
  //     worksheet.addRow({
  //       employeeId: employee.employeeId,
  //       firstName: `${employee.firstName} ${employee.lastName}`,

  //       date: employee.date,
  //       checkIn: employee.checkIn,
  //       checkOut: employee.checkOut,
  //       checkinLocation: employee.checkinLocation,
  //       status: employee.date === todayDate ? "Present" : "Absent",
  //     });
  //   });

  //   worksheet.getRow(1).font = { bold: true, color: "green" };
  //   worksheet.getRow(1).fill = {
  //     type: "pattern",
  //     pattern: "solid",
  //     fgColor: { argb: "FF0000FF" },
  //   };

  //   worksheet.eachRow((row, rowNumber) => {
  //     if (rowNumber > 1) {
  //       const statusCell = row.getCell(7); // Correcting to the 6th cell (status column)
  //       if (statusCell.value === "Present") {
  //         statusCell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "FF00FF00" }, // Green color for Present
  //         };
  //       } else {
  //         statusCell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "FFFF0000" }, // Red color for Absent
  //         };
  //       }
  //     }
  //   });

  //   const buffer = await workbook.xlsx.writeBuffer();
  //   saveAs(new Blob([buffer]), "Employee_Attendance.xlsx");
  // };

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Attendance Data");
  
    worksheet.columns = [
      { header: "Employee ID", key: "employeeId", width: 15 },
      { header: "Employee Name", key: "firstName", width: 15 },
      { header: "Date", key: "date", width: 15 },
      { header: "Punch In Time", key: "checkIn", width: 20 },
      { header: "Punch Out Time", key: "checkOut", width: 20 },
      { header: "Location", key: "checkinLocation", width: 30 },
      { header: "Status", key: "status", width: 15 },
    ];
  
    attendance.forEach((employee) => {
      const checkIn = employee.checkIn || "Not Punched in Yet";
      const checkOut = employee.checkOut || "Not Punched out Yet";
      const location = employee.checkinLocation || "Location Not Found";
  
      worksheet.addRow({
        employeeId: employee.employeeId,
        firstName: `${employee.firstName} ${employee.lastName}`,
        date: employee.date,
        checkIn: checkIn,
        checkOut: checkOut,
        checkinLocation: location,
        status: employee.date === todayDate ? "Present" : "Absent",
      });
    });
  
    worksheet.getRow(1).font = { bold: true, color: "green" };
    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF0000FF" },
    };
  
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        const statusCell = row.getCell(7);
        if (statusCell.value === "Present") {
          statusCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF00FF00" },
          };
        } else {
          statusCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF0000" },
          };
        }
      }
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "Employee_Attendance.xlsx");
  };
  


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Paginate attendance
  const paginatedAttendance = attendance.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Container elevation={2}>
      <Title variant="h5">Employees Attendance Management</Title>
      <SignedInSection>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Typography variant="h4" ml={3} mt={3}>
            Employees Attendance:
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#7e31ce",
              marginRight:'20px',
              color: "#fff",
              "&:hover": { backgroundColor: "#7e31ce" },

            }}
            onClick={handleExport}
          >
            <ScreenShareOutlinedIcon sx={{ marginRight: 1 }} />{" "}
            {/* Insert custom Excel icon */}
            Export
          </Button>
        </Box>

        <Box mt={3}>
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="tableHeader">
                  <TableRow>
                    <TableHeaderCell>Thumbnail</TableHeaderCell>
                    <TableHeaderCell>Employee Name</TableHeaderCell>
                    <TableHeaderCell>Date</TableHeaderCell>
                    <TableHeaderCell>Punch In Time</TableHeaderCell>
                    <TableHeaderCell>Punch Out Time</TableHeaderCell>
                    <TableHeaderCell>Location</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Action</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loader ? (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100px"
                        >
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    paginatedAttendance.map((employee) => (
                      <TableRow key={employee.id}>
                        <TableCell
                          className="tableCell"
                          style={{
                            border: "0.1rem solid black",
                            textAlign: "center",
                            fontWeight: "bold",
                            cursor:'pointer',
                          }}
                        >
                          <Avatar
                            alt={employee.firstName}
                            style={{ marginLeft: "25%", marginBottom: "10px" }}
                            src={employee.checkInPic}
                            onClick={() => handleOpenModal(employee)}
                          />
                          {employee.employeeId}
                        </TableCell>

                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "0.1rem solid black",
                          }}
                        >
                          {employee.firstName} {employee.lastName}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "0.1rem solid black",
                          }}
                        >
                          {employee.date}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "0.1rem solid black",
                          }}
                        >
                          {/* {(employee.checkIn)} */}
                          {(employee.checkIn) || 'Not Punched in Yet'}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "0.1rem solid black",
                          }}
                        >
                          {/* {(employee.checkOut)} */}
                          {(employee.checkOut) || 'Not Punched out Yet'}
                        </TableCell>


                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "0.1rem solid black",
                            padding: 0,  // Remove padding from the cell
                            verticalAlign: "top",  // Ensure content is aligned to the top
                            width:'20%'
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="100%" // Ensure the box takes up the full width
                          
                          >
                            
                            {/* Check-in location */}
                            <Box
                              style={{
                                width: "100%",
                                maxHeight: "50px", // Set a max height for the check-in section
                                overflowY: "auto", // Enable vertical scrolling if content overflows
                                padding: "8px 0",
                              }}
                            >
                              <Typography variant="body2" style={{ fontSize: "15px" }}>
                                {truncateString(employee.checkinLocation, 300)}
                              </Typography>
                            </Box>

                            {/* Divider Line */}
                            <Box
                              component="hr"
                              sx={{
                                width: "100%",
                                border: "none",           // Remove default border
                                height: "1px",            // Set line height to 1px for thinness
                                backgroundColor: "black", // Set the line color to black
                                marginTop: 1.5,                // Remove any margin
                              }}
                            />

                            {/* Check-out location */}
                            <Box
                              style={{
                                width: "100%",
                                maxHeight: "50px", // Set a max height for the check-out section
                                overflowY: "auto", // Enable vertical scrolling if content overflows
                                padding: "8px 0",
                              }}
                            >
                              <Typography variant="body2" style={{ fontSize: "15px" }}>
                                {truncateString1(employee.checkoutLocation, 300)}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell
                          className="tableCell"
                          style={{
                            color: "green",
                            fontSize: "17px",
                            textAlign: "center",
                            fontWeight: "bold",
                            border: "0.1rem solid black",
                          }}
                        >
                          {employee.date === todayDate ? "Present" : "Absent"}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{ border: "0.1rem solid black" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#7e31ce",
                                color: "#fff",
                                "&:hover": { backgroundColor: "#7e31ce" },
                                marginRight: "10px",
                              }}
                              onClick={() => handleClickOpen(employee)}
                            >
                              Attendance
                            </Button>

                            
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={attendance.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Box>
      </SignedInSection>

<Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
  <DialogTitle>
    {/* <strong style={{ fontSize: '20px' }}>Punch Details</strong> */}
    <strong style={{ fontSize: '20px' }}>Punch Details - 
      
    <strong style={{ color:'#7e31ce' }}> {selectedEmployee?.firstName} {selectedEmployee?.lastName} </strong>
    </strong>
    <IconButton
  aria-label="close"
  onClick={handleCloseModal}
  sx={{
    position: "absolute",
    right: 8,
    top: 8,
    color: "black", // Change the color to your desired value
  }}
>
  <CloseIcon />
</IconButton>

  </DialogTitle>
 
  <DialogContent >
    {selectedEmployee && (
      <Box
      
        gap={2}
      >
         {/* <Divider sx={{ width: "100%", marginY: 2 }} /> */}
        {/* Punch In Details */}
        <Card sx={{ maxWidth: 900 }}>
        <strong style={{ fontSize: '15px',padding:'20px',color:'#0c8091' }}>Punch In Details</strong>
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <Avatar
                alt="Punch In Photo"
                src={selectedEmployee.checkInPic}
                sx={{ width: 150, height: 150 }}
              />
              <Box>
                <Typography variant="h6" style={{ fontWeight: 'bold',paddingLeft:'25px',color:'#7e31ce' }}>Punch In Time: </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'Green',paddingTop:5}}>
                {(selectedEmployee.checkIn) || 'N/A'}
                </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'#7e31ce'}} mt={2}>
                  Punch In Address:
                </Typography>
                <Typography style={{paddingLeft:'25px',paddingTop:5}}>
                  {truncateString(selectedEmployee.checkinLocation, 300)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {/* Divider */}
        <Divider sx={{ width: "100%", marginY: 2 }} />

        {/* Punch Out Details */}
        <Card sx={{ maxWidth: 900, boxShadow: 1 }}>
        <strong style={{ fontSize: '15px',padding:'20px',color:'#0c8091' }}>Punch Out Details</strong>
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <Avatar
                alt="Punch Out Photo"
                src={selectedEmployee.checkOutPic}
                sx={{ width: 150, height: 150 }}
              />
              <Box>
                <Typography variant="h6" style={{ fontWeight: 'bold',paddingLeft:'25px',color:'#7e31ce' }}>Punch Out Time: </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'Green',paddingTop:5}}>
                {(selectedEmployee.checkOut) || 'N/A'}
                </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'#7e31ce'}} mt={2}>
                  Punch Out Address:
                </Typography>
                <Typography style={{paddingLeft:'25px',paddingTop:5}}>
                  {truncateString(selectedEmployee.checkoutLocation, 300)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    )}
  </DialogContent>
  
</Dialog>

    </Container>
  );
};

export default EmployeeAttendance;